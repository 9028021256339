import React from 'react';
import './../../../components/shared-components/scan-sticker/scan-sticker.scss';

export default function AuditScanSticker({ scanSuccess, scanResponseDataCurated }) {
  
    return (
      <>
        {
            <div className="ScanSticker">
              {
                scanSuccess && scanResponseDataCurated?.waybillNo ? 
                <div className='flex-column flex-gap-l'>
                  {
                    scanResponseDataCurated.clientMetaData &&
                    (
                      scanResponseDataCurated.clientMetaData?.revSortCode || 
                      scanResponseDataCurated.clientMetaData.priorityTag
                    ) ?
                    <div className='flex-box flex-gap-l align-items-stretch'>
                      {
                        (scanResponseDataCurated.clientMetaData?.revSortCode) &&
                        <div className='flex-column flex-gap-xs align-items-center spacer-s border-radius-s'
                         style={{
                          color : scanResponseDataCurated.clientMetaData.isPriority ? "white" : "black",
                          backgroundColor : scanResponseDataCurated.clientMetaData.isPriority ? "red" : "white"
                         }}>
                          <div className='font-size-l-1 text-bold'> 
                            {scanResponseDataCurated.clientMetaData?.revSortCode} 
                          </div>
                          <div> Sort Code </div>
                        </div>
                      }
                      {
                        (scanResponseDataCurated.clientMetaData.priorityTag) &&
                        <div className='flex-column font-size-l-1 justify-content-center flex-gap-l align-items-center spacer-m border-radius-s'
                        style={{ 
                          color : scanResponseDataCurated.clientMetaData.priorityColor ? "white" : "black",
                          backgroundColor : scanResponseDataCurated.clientMetaData?.priorityColor || "white"
                        }}>
                          {scanResponseDataCurated.clientMetaData.priorityTag}
                        </div>
                      }
                    </div>
                    : <></>
                  }
                  <div className="audit-scan-box flex-column flex-gap-l" style={{ fontSize : 14 }}>
                    <div className='flex-box justify-content-space-evenly'>
                      <div className='flex-1 flex-column flex-gap-xs'>
                        {
                          (scanResponseDataCurated?.waybillNo) ? 
                          <>
                            <div className="key">Waybill number</div>
                            <div className="value">{scanResponseDataCurated.waybillNo}</div>
                          </> : 
                          null
                        }
                      </div>
                      <div className='flex-1 flex-column flex-gap-xs'>
                        <div className="key">Last attempted at</div>
                        <div className="value">{scanResponseDataCurated.lastAttemptDate || "NA"}</div>
                      </div>
                      <div className='flex-1 flex-column flex-gap-xs'>
                        <div className="key">No. of attempts</div>
                        <div className="value">{scanResponseDataCurated.numberOfAttempts}</div>
                      </div>
                    </div>
                    {
                      scanResponseDataCurated.clientMetaData &&
                      <div className='flex-box justify-content-space-between'>
                        <div className='flex-1 flex-column flex-gap-xs'>
                          <div className="key">Category</div>
                          <div className="value">{scanResponseDataCurated.clientMetaData.instructionSummary.join(", ")}</div>
                        </div>
                        <div className='flex-1 flex-column flex-gap-xs'>
                          <div className="key">Expected Delivery Date</div>
                          <div className="value">{scanResponseDataCurated.clientMetaData.expectedDeliveryDate || "--"}</div>
                        </div>
                        <div className='flex-1 flex-column flex-gap-xs'>
                          <div className="key">Next OFD Date</div>
                          <div className="value">{scanResponseDataCurated.clientMetaData.nextOutForDelivery}</div>
                        </div>
                      </div>
                    }
                    <div className='flex-box justify-content-space-between'>
                      <div className='flex-1 flex-column flex-gap-xs'>
                        {
                          (scanResponseDataCurated?.consignmentStatus) ? 
                          <>
                            <div className="key">Current status</div>
                            <div className="value">{scanResponseDataCurated.consignmentStatus}</div>
                          </> : 
                          null
                        }
                      </div>
                      <div className='flex-1 flex-column flex-gap-xs'>
                          <div className="key">Is Priority</div>
                          <div className="value">{
                            scanResponseDataCurated.clientMetaData ?
                            scanResponseDataCurated.clientMetaData.isPriority ? "YES" : "NO" :
                            scanResponseDataCurated.priority || "NA"
                          }</div>
                      </div>
                      <div className='flex-1 flex-column flex-gap-xs'>
                          <div className="key">Flow Type</div>
                          <div className="value">{scanResponseDataCurated.flowType}</div>
                      </div>
                    </div>
                  </div>
                </div> : 
                <div className="scan-box"></div>
              }
            </div>
        }
      </>

    )
  }